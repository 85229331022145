import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import "./contacts.scss";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

export function Contacts(props) {
    return (
        <div id="contacts-page">
            <h2>Наші контакти</h2>
            <p className="phone">+380 (00) 000 00 00</p>
            <p className="phone">+380 (00) 000 00 00</p>
            <p className="phone">+380 (00) 000 00 00</p>
            <p className="office">м. Львів, вул. Прикладова, 1</p>
            <div id="map">
                <Map
                    google={props.google}
                    initialCenter={{
                        lat: 49.842957,
                        lng: 24.031111
                    }}
                    zoom={15}>
                    <Marker position={{
                        lat: 49.842957,
                        lng: 24.031111
                    }} />
                </Map>
            </div>
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyC71ekBkj2aWOpJ3bGRt7f7d448VEb-cvY",
})(Contacts);