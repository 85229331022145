import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import "./noPage.scss";
import {
    BrowserRouter as Router,
    useHistory,
    Route, Switch, Link
} from 'react-router-dom';

export default function NoPage(props) {
    const history = useHistory();
    return (
        <div id="no-page">
            <h1>404</h1>
            <p>сторінку не знайдено :(</p>
            <img src="../../src/logo.png" alt="" />
            <button onClick={() => history.push("/")}>назад на головну</button>
        </div>
    );
}