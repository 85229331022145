
import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import {
    BrowserRouter as Router,
    Route,
    useParams, useHistory
} from 'react-router-dom';
import { db, storage } from '../../../js/firebase';


export default function AdministratePoll(props) {
    const [poll, setpoll] = useState();
    const [header, setheader] = useState("");
    const [description, setdescription] = useState("");
    const [elements, setelements] = useState([]);
    const [votes, setvotes] = useState([]);

    const [mode, setmode] = useState("g-loading");
    const [error, seterror] = useState("");
    const [message, setmessage] = useState("");
    const history = useHistory();

    let { pollId } = useParams();


    function updateElement(i, newElement) {
        let tempElements = [...elements];
        tempElements[i] = newElement;
        setelements(tempElements);
    }
    function addElement() {
        let tempElements = [...elements];
        tempElements.push({ header: "", pic: "", description: "" });
        console.log('add');
        setelements(tempElements);
    }

    function deleteElement(i) {
        let tempElements = [...elements];
        tempElements.splice(i, 1);
        setelements(tempElements);
    }

    function save() {
        if (elements.find(e => e.header == ""))
            return seterror("Всі елементи мають мати заголовок");
        if (elements.find(e => e.pic == ""))
            return seterror("Всі елементи мають мати картинку");
        seterror("");
        setmode("loading");
        db.collection("polls").doc(pollId).set(poll).then(() => setmode(""));
        setmessage({ shown: true, content: "збережено успішно!" });
        setTimeout(() => {
            setmessage({ shown: false, content: "збережено успішно!" })
        }, 2000);
    }

    function create() {
        if (header.length < 2)
            return seterror("У опитування немає заголовка");
        if (elements.find(e => e.header == ""))
            return seterror("Всі елементи мають мати заголовок");
        if (elements.find(e => e.pic == ""))
            return seterror("Всі елементи мають мати картинку");
        if (elements.length < 2)
            return seterror("В голосуванні має бути хоча би 2 елементи");
        seterror("");
        setmode("loading");
        db.collection("polls").add({ ...poll, ...{ created: new Date() } }).then(() => setmode(""));
        setmessage({ shown: true, content: "голосування створено успішно!" });
        setTimeout(() => {
            setmessage({ shown: false, content: "голосування створено успішно!" })
        }, 2000);
    }

    useEffect(() => {
        let tempPoll = poll ? poll : {};
        tempPoll.elements = elements;
        tempPoll.header = header;
        tempPoll.votes = votes;
        tempPoll.description = description;
        setpoll(tempPoll);
    }, [elements, header, votes, description]);

    useEffect(() => {
        if (!poll)
            return;
        setheader(poll.header);
        setdescription(poll.description);
        setvotes(poll.votes);
        setelements(poll.elements);
    }, [poll]);

    useEffect(() => {
        if (!pollId || pollId == "new")
            return setmode("new-poll");
        db.collection("polls").doc(pollId).get().then(data => {
            setpoll(data.data());
            setmode("active");
        })
    }, []);

    if (mode == "g-loading")
        return <div style={{ marginTop: "10px" }} className="loading-circle" />
    return (
        <div id="a-poll-block">
            <input id="poll-header" placeholder="Заголовок опитування" value={header} onChange={e => setheader(e.target.value)} />
            <input id="poll-description" placeholder="Опис опитування" value={description} onChange={e => setdescription(e.target.value)} />
            <div id="poll-elements">
                {
                    elements.map((element, i) => {
                        return <PollElement key={i} update={element => updateElement(i, element)} deleteElement={() => deleteElement(i)} element={element} />
                    })
                }
                <div id="b-add" onClick={() => addElement()} >+</div>
            </div>
            {
                mode != "loading" ?
                    <button onClick={() => mode == "new-poll" ? create() : save()}>{mode == "new-poll" ? "створити" : "зберегти"}</button>
                    :
                    <div className="loading-circle" />
            }
            <button style={{ marginTop: "10px" }} onClick={() => history.push("/administration")}>назад</button>
            <p className={`error ${error ? "" : "hidden"}`}>{error}</p>
            <p className={`message ${message.shown ? "" : "hidden"}`}>{message.content}</p>
        </div>
    )
}

function PollElement(props) {
    const [element, setelement] = useState(props.element);
    const [header, setheader] = useState(element.header);
    const [description, setDescription] = useState(element.description);
    const [pic, setpic] = useState(element.pic);
    const [mode, setmode] = useState("");

    useEffect(() => {
        let tempElement = element;
        tempElement.header = header;
        tempElement.description = description;
        tempElement.pic = pic;
        setelement(tempElement);
    }, [header, description, pic]);

    useEffect(() => {
        props.update(element);
    }, [element]);

    function uploadImage(ev) {
        const file = ev.target.files[0];
        const name = `bg-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.${file.type.split("/")[1]}`;
        if (file.type.split("/")[0] != "image") return setmode("error");
        const metadata = { contentType: file.type };
        const task = storage.ref().child("poll_imgs").child(name).put(file, metadata);
        task.resume();
        setmode("loading");
        task.then(snap => {
            setmode("");
            storage.ref().child("poll_imgs").child(name).getDownloadURL().then(url => {
                setpic(url);
            })
        })
            .catch(err => {
                setmode("error");
            })
    }

    return <div className="poll-element" >
        <div className="element-image" style={{ backgroundImage: `url('${pic.match(/\.(jpeg|jpg|gif|png)/) ? pic : "/src/noimg.png"}')` }} >
            <div className="b-delete" onClick={() => props.deleteElement()}>X</div>
        </div>
        <input className="element-header" placeholder="заголовок елементу" value={header} onChange={e => setheader(e.target.value)} />
        <input className="element-description" placeholder="опис елементу" value={description} onChange={e => setDescription(e.target.value)} />
        <input className="element-pic" placeholder="URL картинки" value={pic} readOnly />
        {mode != "loading" ? <input type="file" accept="image/*" onChange={uploadImage} placeholder="загрузити" /> : <div className="loading-circle" />}
    </div>
}