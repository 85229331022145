import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom'
import { db, auth } from '../../../js/firebase';

export default function RightEdit() {
    const [admins, setadmins] = useState([]);
    const [error, seterror] = useState();
    const [addEmail, setaddEmail] = useState("");
    useEffect(() => {
        auth.onAuthStateChanged(usr => {
            // console.log(usr);
            db.collection("admins").onSnapshot(data => {
                let tempAdmins = [];
                data.docs.map(doc => {
                    tempAdmins.push({ ...doc.data(), ...{ id: doc.id } });
                });
                tempAdmins = tempAdmins.filter(adm => adm.id != usr.uid);
                setadmins(tempAdmins);
            })
        })
    }, []);

    useEffect(() => {
        seterror("");
    }, [addEmail]);

    function catchError(err) {
        console.error(err);
    }

    function add() {
        if (!checkEmail)
            return;
        function createAdmin(callback = (res) => { }, error = (err) => { }) {
            db.collection("admins").where("email", "==", addEmail).get().then(docs => {
                if (docs.empty)
                    db.collection("admins").add({ email: addEmail }).then(() => callback()).catch(err => error(err));
                else
                    return seterror("такий email вже є адміном");
            })

        }
        db.collection("users").where("email", "==", addEmail).get().then(docs => {
            if (docs.empty)
                return createAdmin(() => { }, err => catchError(err));
            docs.docs[0].ref.delete().then(() => createAdmin(() => { }, err => catchError(err)));
        });
    }

    function deleteAdmin(id) {
        db.collection("admins").doc(id).delete();
    }

    function checkEmail() {
        return addEmail.length > 1 && addEmail.indexOf("@") > -1 && addEmail.indexOf(".") > -1;
    }

    return (
        <div id="a-edit-rights">
            <div id="add-holder" className="vertical-holder">
                <input placeholder="email користувача, якого ви хочете зробити адміном"
                    className={`${checkEmail() ? "" : "error"}`}
                    value={addEmail} onChange={e => setaddEmail(e.target.value)} />
                <button disabled={!checkEmail()} onClick={add}>додати</button>
            </div>
            {
                error &&
                <p className="error-box">{error}</p>
            }
            {
                admins.map(adm => <div key={adm.id} className="a-item">
                    <p>{adm.email}</p>
                    <div style={{ marginLeft: "auto" }} className="b-delete" onClick={() => deleteAdmin(adm.id)}>видалити акаунт</div>
                </div>)
            }
            {
                admins.length == 0 &&
                <p className="center-msg">Немає адмінів крім вас</p>
            }
        </div>
    )
}