import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import {
    BrowserRouter as Router,
    useHistory,
    Route, Switch, Link
} from 'react-router-dom';
import Index from './pages/index/index';
import ReactDOM from "react-dom";
import Header from './header/header';
import Contacts from './pages/contacts/contacts';
import Administration from './pages/administration/administration';
import NoPage from './pages/noPage/noPage';
import AdministratePoll from './pages/pollEdit/pollEdit';
import { db, auth } from '../js/firebase';
import RightEdit from './pages/rightsEdit/rightsEdit';
import UsersInfo from './pages/usersInfo/usersInfo';
import HatEdit from './pages/hatEdit/hatEdit';

export default function render(to) {
    ReactDOM.render(<Site />, to);
}

function Site() {
    const [mode, setmode] = useState("loading");
    const [currentPoll, setcurrentPoll] = useState();
    useEffect(() => {
        auth.onAuthStateChanged(usr => {
            if (!usr)
                return setmode("default");
            db.collection("admins").doc(usr.uid).get().then(data => {
                if (data.exists)
                    return setmode("admin");
                else
                    setmode("default");
            });
        })
    }, []);

    return (
        <Router>
            <Header mode={mode} poll={currentPoll} />

            <Switch>
                <Route exact path="/">
                    <Index setHeaderPoll={poll => setcurrentPoll(poll)} />
                </Route>

                <Route path="/contacts" >
                    <Contacts />
                </Route>
                {
                    mode == "default" &&
                    <Route exact path={"/administration"} children={<Administration />} />
                }
                {
                    mode == "admin" &&
                    <>
                        <Route exact path={"/administration"} children={<Administration />} />
                        <Route exact path={"/administration/rights"} children={<RightEdit />} />
                        <Route exact path={"/administration/hatedit"} children={<HatEdit />} />
                        <Route exact path={"/administration/users"} children={<UsersInfo />} />
                        <Route exact path={"/administration/p/:pollId"} children={<AdministratePoll />} />
                    </>
                }
                {
                    mode == "loading" &&
                    <>
                        <Route exact path={"/administration"} children={<div className="loading-circle" style={{ marginTop: "10px" }} />} />
                        <Route exact path={"/administration/rights"} children={<div className="loading-circle" style={{ marginTop: "10px" }} />} />
                        <Route exact path={"/administration/users"} children={<div className="loading-circle" style={{ marginTop: "10px" }} />} />
                        <Route exact path={"/administration/hatedit"} children={<div className="loading-circle" style={{ marginTop: "10px" }} />} />
                        <Route exact path={"/administration/p/:pollId"} children={<div className="loading-circle" style={{ marginTop: "10px" }} />} />
                    </>
                }
                <Route>
                    <NoPage />
                </Route>
            </Switch>
        </Router>
    )
}