exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var getUrl = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("./bloggersansbold.woff2"));
var ___CSS_LOADER_URL___1___ = getUrl(require("./bloggersansbold.woff"));
var ___CSS_LOADER_URL___2___ = getUrl(require("./bloggersansbolditalic.woff2"));
var ___CSS_LOADER_URL___3___ = getUrl(require("./bloggersansbolditalic.woff"));
var ___CSS_LOADER_URL___4___ = getUrl(require("./bloggersansitalic.woff2"));
var ___CSS_LOADER_URL___5___ = getUrl(require("./bloggersansitalic.woff"));
var ___CSS_LOADER_URL___6___ = getUrl(require("./bloggersanslight.woff2"));
var ___CSS_LOADER_URL___7___ = getUrl(require("./bloggersanslight.woff"));
var ___CSS_LOADER_URL___8___ = getUrl(require("./bloggersansmedium.woff2"));
var ___CSS_LOADER_URL___9___ = getUrl(require("./bloggersansmedium.woff"));
var ___CSS_LOADER_URL___10___ = getUrl(require("./bloggersansmediumitalic.woff2"));
var ___CSS_LOADER_URL___11___ = getUrl(require("./bloggersansmediumitalic.woff"));
// Module
exports.push([module.id, "/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 28, 2019 */\n\n\n\n@font-face {\n    font-family: 'blogger_sansbold';\n    src: url(" + ___CSS_LOADER_URL___0___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL___1___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'blogger_sansbold_italic';\n    src: url(" + ___CSS_LOADER_URL___2___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL___3___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'blogger_sansitalic';\n    src: url(" + ___CSS_LOADER_URL___4___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL___5___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'blogger_sanslight';\n    src: url(" + ___CSS_LOADER_URL___6___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL___7___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'blogger_sansmedium';\n    src: url(" + ___CSS_LOADER_URL___8___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL___9___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'blogger_sansmedium_italic';\n    src: url(" + ___CSS_LOADER_URL___10___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL___11___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}", ""]);
