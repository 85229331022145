import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import "./poll.scss";
import firebase, { db, auth, recapcha } from "../../js/firebase";
import Popup from '../popup';
import InputMask from "react-input-mask";
import RecaptchaContainer from '../recaptcha';
import Auth from '../auth/auth';

export default function Poll(props) {
    const [votes, setvotes] = useState([]);
    const [elements, setelements] = useState(props.elements.map((e, i) => { return { ...e, ...{ votes: votes.filter(v => v.element == i).length, id: i } } }));
    const [mode, setmode] = useState("loading");
    const [authSelection, setauthSelection] = useState(-1);
    const [selected, setselected] = useState(-1);
    const [hidePopup, sethidePopup] = useState(false);

    useEffect(() => {
        db.collection("polls").doc(props.id).collection("votes").get().then(data => {
            setvotes(data.docs.map(doc => { return { ...{ id: doc.id }, ...doc.data() } }));
            setmode("ready");
        })
    }, []);


    useEffect(() => {
        setelements(props.elements.map((e, i) => { return { ...e, ...{ votes: votes.filter(v => v.element == i).length, id: i } } }));
        if (!auth.currentUser)
            return;
        const myVote = votes.find(e => e.user == auth.currentUser.uid);
        // console.log(myVote, votes);
        if (!myVote)
            return;
        if (myVote)
            setselected(myVote.element);
    }, [votes]);

    function select(i) {
        // return console.log(i, selected);
        if (selected > -1) {
            setmode("failed-vote");
            console.log("failure");
            return setTimeout(() => {
                sethidePopup(true);
                setTimeout(() => {
                    setmode("");
                    sethidePopup(false);
                }, 750);
            }, 2500);
        }
        if (!auth.currentUser) {
            setauthSelection(i);
            setmode("no-user");
            return;
        }
        let p_votes = votes;
        p_votes.push({ user: auth.currentUser.uid, element: i });
        setmode("wait");
        setvotes(p_votes);
        setselected(i);
        setTimeout(() => {
            sethidePopup(true);
            setTimeout(() => {
                setmode("");
                sethidePopup(false);
            }, 750);
        }, 2500);
        db.collection("polls").doc(props.id).collection("votes").add({ user: auth.currentUser.uid, element: i }).then(() => setmode("success-vote"));
        // db.collection("polls").doc(props.id).update({ votes: p_votes }).then(() => setmode("success-vote")).catch(err => console.error(err));
    }

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user)
                setmode("active");
        });
    }, []);

    if (mode == "loading")
        return <div className="loading-circle" />

    return (<div className={`poll ${selected > -1 ? "ended" : ""}`}>
        {
            mode == "no-user" &&
            <Popup hidden={hidePopup} light content={() => noUserContent(() => select(authSelection))}></Popup>
        }
        {
            mode == "success-vote" &&
            <Popup hidden={hidePopup} light center content={() => SuccessVote()}></Popup>
        }
        {
            mode == "failed-vote" &&
            <Popup hidden={hidePopup} content={() => FailedVote()}></Popup>
        }
        <h2 id="poll-description">{props.description}</h2>
        <div className="elements">
            {elements.sort((b, a) => a.votes - b.votes).map((e, i) => <PollElement pollstatus={mode} selected={selected} key={i} element={e} select={() => select(e.id)} />)}

        </div>
    </div>)
}

function PollElement(props) {
    const [element, setelement] = useState(props.element);
    const [header, setheader] = useState(element.header);
    const [description, setDescription] = useState(element.description);
    const [votes, setvotes] = useState(element.votes);
    const [pic, setpic] = useState(element.pic);
    const [aspectRatio, setaspectRatio] = useState(false);

    useEffect(() => {
        setelement(props.element);
        setheader(props.element.header);
        setvotes(props.element.votes);
        setDescription(props.element.description);
        setpic(props.element.pic);
    }, [props.element]);

    useEffect(() => {
        let img = new Image();
        img.src = pic;
        img.onload = () => {
            setaspectRatio(img.width > img.height);
        };
    }, [pic]);

    return <div className="poll-element" >
        <div className={`element-image ${aspectRatio ? 'aspected' : 'unaspected'}`} style={{
            backgroundImage: `url('${pic.match(/\.(jpeg|jpg|gif|png)/) ? pic : "/src/noimg.png"}')`
        }} >
        </div>
        <h3>{header}</h3>
        {description && <p>{description}</p>}
        <div className="vote-field">
            <div className="like-counter">
                <img src="/src/heart.png" />
                <p>{votes}</p>
            </div>
            {
                <button disabled={props.pollstatus == "wait"} className={`green b-vote`} onClick={() => {
                    // if (auth.currentUser && props.selected < 0)
                    if (props.selected < 0)
                        setvotes(votes + 1);
                    props.select();
                }}>голосую</button>
            }
        </div>
    </div>
}

function SuccessVote(props) {
    return (
        <div id="success-vote">
            <h2>Дякуємо!</h2>
            <h3>ваш голос зараховано</h3>
        </div>
    )
}

function FailedVote(props) {
    return (
        <div id="failed-vote">
            <img src="../src/no-stopping.png" />
            <h3>голосування можливо лише один раз</h3>
        </div>
    )
}

function noUserContent(authFunc) {
    return <Auth onAuth={authFunc} />
}