import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';

export default function Popup(props) {
    return (
        <div id="over" style={{ position: "fixed", width: "100vw", height: "100vh", left: 0, top: 0, justifyContent: "center", alignItems: "center" }}>
            <div className={`popup ${props.hidden ? "transparent" : ""} ${props.light ? "light" : ""}`} style={{ textAlign: props.center ? "center" : "" }}>
                {<props.content />}
            </div>
        </div>
    )
}