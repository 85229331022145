import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import firebase, { auth } from '../js/firebase';

export default function RecaptchaContainer(props) {
    const [verifier, setverifier] = useState();
    const captchaRef = useRef();
    useEffect(() => {
        captchaRef.current.innerHTML = "";
        setverifier(new firebase.auth.RecaptchaVerifier("recaptcha-container", {
            size: 'normal',
            callback: (res) => {
                props.onRecaptcha(res);
            }
        }));
    }, []);

    useEffect(() => {
        props.setVerifier(verifier);
        if (verifier)
            verifier.render();
    }, [verifier])
    return (
        <div id="recaptcha-container" className={props.className} ref={captchaRef}></div>
    )
}