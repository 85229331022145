import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import "./hatEdit.scss";
import { db, storage } from '../../../js/firebase';

export default function HatEdit(props) {
    const [bg, setbg] = useState("");
    const [position, setposition] = useState("top");
    const [mode, setmode] = useState("loading");
    useEffect(() => {
        db.collection("options").doc("global").get().then(data => {
            const ops = data.data();
            setbg(ops.bg);
            if (ops.bg_position) {
                setposition(ops.bg_position);
            }
            setmode("");
        })
    }, []);

    useEffect(() => {
        if (mode == "error" || mode == "success")
            setTimeout(() => {
                setmode("");
            }, 2000);
    }, [mode]);

    function save(ev = null) {
        setmode("loading");
        db.collection("options").doc("global").update({ bg: bg, bg_position: position }).then(() => {
            setmode("success");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        });
    }
    function upload(ev) {
        const file = ev.target.files[0];
        const name = `bg-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.${file.type.split("/")[1]}`;
        if (file.type.split("/")[0] != "image") return setmode("error");
        const metadata = { contentType: file.type };
        const task = storage.ref().child("bg_pics").child(name).put(file, metadata);
        task.resume();
        setmode("loading");
        task.then(snap => {
            setmode("");
            storage.ref().child("bg_pics").child(name).getDownloadURL().then(url => {
                setbg(url);
            })
        })
            .catch(err => {
                setmode("error");
            })
    }
    return (
        <div id="hatedit-page">
            <div id="l-block">
                <h2>Зміна шапки сайту</h2>
                <div className="pair">
                    <p>посилання на картинку:</p>
                    <input className="inputfile" disabled={mode == "loading"} placeholder="URL картинки" value={bg} readOnly />
                </div>
                {
                    mode != "loading" ?
                        <input type="file" accept="image/*" onChange={upload} placeholder="загрузити нову картинку" />
                        :
                        <div className="loading-circle" style={{ marginTop: "10px" }} />
                }
                <p>позиція фону</p>
                {
                    mode != "loading" &&
                    <div className="select">
                        <button disabled={position == "top"} onClick={() => setposition("top")}>зверху</button>
                        <button disabled={position == "center"} onClick={() => setposition("center")}>посередині</button>
                        <button disabled={position == "bottom"} onClick={() => setposition("bottom")}>знизу</button>
                    </div>
                }
                {
                    mode != "loading" ?
                        <button onClick={save} disabled={!bg || !bg.match(/\.(jpeg|jpg|gif|png)/)}>зберегти</button>
                        :
                        <div className="loading-circle" style={{ marginTop: "10px" }} />
                }
                {
                    mode == "success" &&
                    <p className="success">збережено успішно</p>
                }
                {
                    mode == "error" &&
                    <p className="err">виникли помилки...</p>
                }
            </div>
            <div id="r-block">
                <h2>перегляд</h2>
                <div id="preview">
                    <div id="mini-header" style={{
                        backgroundImage: `url('${bg && bg.match(/\.(jpeg|jpg|gif|png)/) ? bg : "/src/no_bg.jpg"}')`,
                        backgroundPosition: position
                    }}>
                        <div className="elements">
                            <img src="/src/logo.png" />
                            <h3>Приклад</h3>
                        </div>
                    </div>
                    <div id="mini-content">
                        <div id="mini-elements">
                            <div className="mini-element">
                                <div className="image" />
                                <div className="header-t" />
                                <div className="descript-t" />
                            </div>
                            <div className="mini-element">
                                <div className="image" />
                                <div className="header-t" />
                                <div className="descript-t" />
                            </div>
                            <div className="mini-element">
                                <div className="image" />
                                <div className="header-t" />
                                <div className="descript-t" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}