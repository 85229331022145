import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { db, auth } from "../../../js/firebase";
import { useHistory, useParams } from "react-router-dom";
import "./administration.scss";
import Poll from '../../poll/poll';

export default function Administration(props) {
    const [mode, setmode] = useState("loading");
    const [userData, setuserData] = useState();
    const [polls, setpolls] = useState([]);
    const history = useHistory();
    useEffect(() => {
        auth.onAuthStateChanged(usr => {
            if (!usr)
                return setmode("nouser");
            db.collection("admins").doc(auth.currentUser.uid).get().then(data => {
                if (data.exists)
                    setuserData({ ...data.data(), ...{ id: data.id } });
                else
                    setmode("not-admin");
            }).catch(err => {
                console.log(err);
            });
        });
    }, []);

    useEffect(() => {
        if (!userData)
            return;
        setmode("admin");
        db.collection("polls").orderBy("created", "desc").onSnapshot(res => {
            setpolls(res.docs.map(d => { return { ...d.data(), ...{ id: d.id } } }));
        })
    }, [userData]);

    if (mode == "loading")
        return <div style={{ marginTop: "1em" }} className="loading-circle" />


    function deletePoll(id) {
        setpolls(polls.filter(p => p.id != id));
        db.collection("polls").doc(id).delete();
    }
    function PollItem(props) {
        const [poll, setpoll] = useState(props.poll);
        const [confirm, setconfirm] = useState(false);
        return (
            <div className="a-item">
                <p>{poll.header}</p>
                <div className="b-edit" onClick={() => history.push(`${history.location.pathname}/p/${poll.id}`)}>редагувати</div>
                <div className="b-delete" onClick={() => !confirm ? setconfirm(true) : props.deletePoll(poll.id)}>{!confirm ? "видалити" : "точно видалити?"}</div>
            </div>
        )
    }

    return (
        <div id="admin-page">
            {
                mode == "not-admin" &&
                <div id="not-admin">
                    <h1>Ой!</h1>
                    <h2>схоже, що ви не адміністратор сайту :(</h2>
                    <button onClick={() => auth.signOut().then(() => window.location.reload())}>вийти з акаунту</button>
                </div>
            }
            {
                mode == "nouser" &&
                <LoginContent />
            }
            {
                mode == "admin" &&
                <div id="polls">
                    <div className="a-item" id="b-poll-add" onClick={() => history.push(`${history.location.pathname}/p/new`)} >додати нове голосування</div>
                    <div className="a-item" onClick={() => history.push(`${history.location.pathname}/hatedit`)} >змінити шапку сайту</div>
                    <div className="a-item" onClick={() => history.push(`${history.location.pathname}/rights`)} >налаштування прав доступу</div>
                    <div className="a-item" onClick={() => history.push(`${history.location.pathname}/users`)} >користувачі сайту</div>
                    <div className="a-item" onClick={() => auth.signOut().then(() => window.location.reload())} >вийти з акаунту</div>

                    {
                        polls.map(
                            poll => <PollItem key={poll.id} poll={poll} deletePoll={deletePoll} />
                        )
                    }
                </div>
            }
            {
                mode == "not-admin" &&
                <p className="err-msg">У вас немає прав доступу</p>
            }
        </div>
    )
}

function LoginContent() {
    const [step, setstep] = useState(0);
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [passwordRepeat, setpasswordRepeat] = useState("");
    const [error, seterror] = useState("");
    const [mode, setmode] = useState("loading");

    function enterEmail() {
        if (email.indexOf("@") < 0)
            return seterror("email має мати @");
        if (email.indexOf(".") < 0)
            return seterror("email мати мати крапку");
        seterror("");
        return checkEmailInBase();
    }
    function checkEmailInBase() {
        setmode("loading");
        db.collection("admins").where("email", "==", email).get().then(data => {
            if (data.empty)
                return seterror("ваш email не має прав доступу до адміністрації сайту");
            else
                auth.fetchSignInMethodsForEmail(email).then(availableMethods => {
                    if (availableMethods.length < 1) {
                        setstep(1);
                        setmode("active");
                    } else {
                        setstep(2);
                        setmode("active");
                    }
                })
        })
    }

    useEffect(() => {
        setmode("");
    }, [error]);

    useEffect(() => {
        auth.onAuthStateChanged(usr => {
            setmode("active");
        })
    }, []);

    function registrate() {
        if (password.length < 6)
            return seterror("пароль має бути довше 6 символів");
        if (password != passwordRepeat)
            return seterror("паролі не співпадають");
        seterror("");
        auth.createUserWithEmailAndPassword(email, password).then(() => window.location.reload());
    }

    function logIn() {
        setmode("loading");
        auth.signInWithEmailAndPassword(email, password)
            .then(res => {
                window.location.reload();
            })
            .catch(err => {
                console.log(err);
                setmode("");
                seterror("непавильний пароль");
            });
    }
    if (mode == "loading")
        return <div style={{ marginTop: "10px" }} className="loading-circle" />
    return <div id="login-window">
        {
            step == 0 &&
            <>
                <input type="email" placeholder="email" value={email} onChange={e => setemail(e.target.value.trim().replace(" ", ""))} />
                <button onClick={() => enterEmail()}>Далі</button>
            </>
        }
        {
            step == 1 &&
            <>
                <p>Вас внесено як адміністратора, але ви ще ні разу не заходили. Тому придумайте собі пароль довжиною від 6 символів для подальшого входу</p>
                <input type="password" placeholder="придумайте собі пароль" value={password} onChange={e => setpassword(e.target.value)} />
                <input type="password" placeholder="повторіть пароль" value={passwordRepeat} onChange={e => setpasswordRepeat(e.target.value)} />
                <button onClick={() => registrate()}>Зареєструватись</button>
            </>
        }
        {
            step == 2 &&
            <>
                <input type="password" placeholder="ваш пароль" value={password} onChange={e => setpassword(e.target.value)} />
                <button onClick={() => logIn()}>Увійти</button>
                {
                }
            </>
        }
        <p className={`error ${error == "" ? "hidden" : ""}`}>{error}</p>
    </div>
}
