import firebase from "firebase";
var firebaseConfig = {
    apiKey: "AIzaSyBkWLnf5n0EX_hs9LGpE3HjgLXE45Jvld4",
    authDomain: "lviv-croissants-restaurant.firebaseapp.com",
    databaseURL: "https://lviv-croissants-restaurant.firebaseio.com",
    projectId: "lviv-croissants-restaurant",
    storageBucket: "lviv-croissants-restaurant.appspot.com",
    messagingSenderId: "276366127518",
    appId: "1:276366127518:web:3f4d12852994d52011668f",
    measurementId: "G-3BWH088SZW"
};
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const recapcha = firebase.auth.RecaptchaVerifier;
export const db = firebase.firestore();
export const storage = firebase.storage();
export default firebase;