import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import "./header.scss";
import { useHistory, } from "react-router-dom";
import { auth, db } from '../../js/firebase';
export default function Header(props) {
    const history = useHistory();
    const location = history.location.pathname;
    const [bg, setbg] = useState("");
    const [bg_position, setbg_position] = useState("top");
    const [mode, setmode] = useState("loading");
    useEffect(() => {
        auth.onAuthStateChanged(usr => {
            setmode("");
        });
        db.collection("options").doc("global").get().then(data => {
            const ops = data.data();
            setbg(ops.bg);
            if (ops.bg_position)
                setbg_position(ops.bg_position);
        })
        console.log(props.mode);
    }, []);
    return (
        <header style={{ backgroundImage: bg != "" ? `url(${bg})` : "url('/src/no_bg.jpg')", backgroundPosition: bg_position }}>
            <div id="items-holder">
                <img src="/src/logo.png" onClick={() => history.push("/")} />
                {
                    props.poll &&
                    <h1>{props.poll.header}</h1>
                }
            </div>
        </header>
    );
}