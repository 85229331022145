import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import firebase, { db, auth, recapcha } from "../../js/firebase";
import RecaptchaContainer from '../recaptcha';
import InputMask from "react-input-mask";

export default function Auth(props) {
    const [recaptcha, setrecaptcha] = useState(false);
    const [verifier, setverifier] = useState();
    return (
        <>
            <RecaptchaContainer className={recaptcha ? "hidden" : ""} setVerifier={verif => setverifier(verif)} onRecaptcha={() => setrecaptcha(true)} />
            {recaptcha &&
                <Content verifier={verifier} recapcha={recapcha} onAuth={props.onAuth} />
            }
        </>
    )
}


function Content(props) {
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [code, setcode] = useState("");
    const [mode, setmode] = useState("");
    const [attempt, setattempt] = useState(0);
    const [confirmator, setconfirmator] = useState();
    const [timer, settimer] = useState(60);
    const [error, seterror] = useState();

    useEffect(() => {
        if (error && mode == "loading")
            setmode("");
    }, [error]);

    useEffect(() => {
        if (timer > 0 && timer < 60) {
            seterror(`ви зробили забагато спроб. Спробуйте через ${timer} сек.`);
            setTimeout(() => {
                settimer(timer - 1);
            }, 1000);
        }
        if (timer == 0) {
            settimer(60);
            seterror("");
            setattempt(0);
        }
    }, [timer]);

    function logIn() {
        const prevMode = mode;
        setmode("loading");
        if (attempt > 1) {
            if (timer == 60)
                settimer(59);
            setmode(prevMode);
            return seterror(`ви зробили забагато спроб. Спробуйте через ${timer} сек.`);
        }
        db.collection("users").where("phone", "==", phone).get().then(data => {
            if (data.empty) {
                setattempt(attempt + 1);
                setmode(prevMode);
                return seterror("користувача з таким номером телефону не існує");
            }
            auth.signInWithPhoneNumber(phone, props.verifier).then(confirmator => {
                setconfirmator(confirmator);
                setmode("confirm-code-login");
            }).catch(err => {
                if (err.code == "auth/too-many-requests") {
                    setmode(prevMode);
                    return seterror(`ви зробили забагато спроб. Зачекайте певний час і спробуйте знову.`);
                }
            });
        })
    }

    function registrate() {
        if (attempt > 1) {
            settimer(59);
            return seterror(`ви зробили забагато спроб. Спробуйте через ${timer} сек.`);
        }
        if (phone.indexOf("_") > -1 || phone.length < 2)
            return seterror("невірно введено номер телефону");
        if (email.indexOf("@") < 0 || email.indexOf(".") < 0)
            return seterror("невірно введено email");
        if (name.length < 2)
            return seterror("невірно введено ім'я");
        if (!props.recapcha)
            return seterror("виникли проблеми з капчею");
        seterror(undefined);
        setmode("loading");
        setattempt(attempt + 1);

        db.collection("users").where("phone", "==", phone).get().then(usersData => {
            if (!usersData.empty)
                return seterror("користувач з таким номером телефону вже існує");
            db.collection("users").where("email", "==", email).get().then(emailData => {
                if (!usersData.empty)
                    return seterror("користувач з таким email-ом вже існує");

                db.collection("admins").where("email", "==", email).get().then(adminData => {
                    if (!adminData.empty)
                        return seterror("користувач з таким email-ом вже існує");
                    const phoneClear = "+" + phone.replace(/\D/g, '');
                    auth.signInWithPhoneNumber(phoneClear, props.verifier)
                        .then(confirmator => {
                            setconfirmator(confirmator);
                            setmode("confirm-code");
                        })
                        .catch(err => {
                            if (err.code == "auth/too-many-requests")
                                return seterror("ви зробили забагато спроб, спробуйте пізніше");
                        });
                })
            })
        })
    }

    function confirm(code, createNewUser = true) {
        setmode("loading");
        if (code.length < 6)
            return seterror("невірно введено код");
        confirmator.confirm(code)
            .then(res => {
                if (createNewUser)
                    db.collection("users").doc(auth.currentUser.uid).set({ name: name, email: email, phone: phone }).then(() => {
                        setmode("success");
                        // setTimeout(() => { window.location.reload(); }, 2000);
                        props.onAuth();
                    });
                else {
                    setmode("success");
                    props.onAuth();
                }
            })
            .catch(err => {
                setmode("confirm-code-login");
                if (err.code == "auth/invalid-verification-code")
                    return seterror("невірно введено код");
            })
    }

    if (mode == "confirm-code")
        return (
            <>
                <h3>останній крок</h3>
                <p>вам надіслано код на телефон, введіть його тут</p>
                <p className={`error ${error != "" ? "" : "hidden"}`}>{error}</p>
                <input className={`${error == "невірно введено код" ? "error" : ""}`} value={code} onChange={e => setcode(e.target.value)} type="text" placeholder="код" />
                <button className="green" onClick={e => confirm(code)}>зареєструватись</button>
            </>
        )
    if (mode == "confirm-code-login")
        return (
            <>
                <h3>СМС Код</h3>
                <p>вам надіслано код на телефон, введіть його тут</p>
                <p className={`error ${error != "" ? "" : "hidden"}`}>{error}</p>
                <input className={`${error == "невірно введено код" ? "error" : ""}`} value={code} onChange={e => setcode(e.target.value)} type="text" placeholder="код" />
                <button className="green" onClick={e => confirm(code, false)}>увійти</button>
            </>
        )
    if (mode == "success")
        return (
            <>
                <h3>вас успішно зареєстровано!</h3>
                <p>тепер ви можете приймати участь у наших голосуваннях :)</p>
            </>
        )
    if (mode == "already-registred")
        return (
            <>
                <div className="pair">
                    <p>номер тел:</p>
                    <InputMask className={`${error == "невірно введено номер телефону" ? "error" : ""}`} value={phone} onChange={e => setphone(e.target.value)} type="phone" mask="+38 (bbb) bbb bb bb" maskChar="_" formatChars={{ 'b': '[0-9]' }} />
                </div>
                <button className="green" onClick={() => logIn()}>увійти в акаунт</button>
                <p className={`error ${error != "" ? "" : "hidden"}`}>{error}</p>
            </>
        )
    if (mode == "loading")
        return <div className="loading-circle light" style={{ alignSelf: "center", margin: "auto" }} />
    return (
        <>
            <h3>для голосування введіть ваші дані:</h3>
            <div className="pair">
                <p>ім'я:</p>
                <input className={`${error == "невірно введено ім'я" ? "error" : ""}`} value={name} onChange={e => setname(e.target.value.trim())} type="name" />
            </div>
            <div className="pair">
                <p>ваш email:</p>
                <input className={`${error == "невірно введено email" ? "error" : ""}`} value={email} onChange={e => setemail(e.target.value.toLowerCase().trim())} type="email" />
            </div>
            <div className="pair">
                <p>номер тел:</p>
                <InputMask className={`${error == "невірно введено номер телефону" ? "error" : ""}`} value={phone} onChange={e => setphone(e.target.value)} type="phone" mask="+38 (bbb) bbb bb bb" maskChar="_" formatChars={{ 'b': '[0-9]' }} />
            </div>

            {
                mode != "loading" ?
                    <button className="green" onClick={() => registrate()}>голосую</button>
                    :
                    <div className="loading-circle" />
            }
            {
                <p className="underlined" style={{ textAlign: "center" }} onClick={() => setmode("already-registred")}>я вже зареєстрований</p>
            }
            {error &&
                <p className={`error ${error ? "" : "hidden"}`}>{error}</p>
            }
        </>
    )
}