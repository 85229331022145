import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { db } from '../../../js/firebase';

export default function UsersInfo() {
    const [docs, setdocs] = useState([]);
    const [mode, setmode] = useState("loading");
    useEffect(() => {
        db.collection("users").orderBy("name").onSnapshot(docs => {
            setdocs(docs.docs.map(d => { return { ...d.data(), ...{ id: d.id } } }))
            setmode("");
        })
    }, []);
    if (mode == "loading")
        return <div className="loading-circle" style={{ marginTop: "10px" }} />
    return (
        <div id="users-info">
            {
                docs.map(doc => <User key={doc.id} setmode={setmode} doc={doc} />)
            }
        </div>
    )
}


function User(props) {
    const user = props.doc;
    function deleteUser() {
        props.setmode("loading");
        db.collection("users").doc(user.id).delete().then(() => {
            props.setmode("");
        });
    }
    return (
        <div className="a-item">
            <p>{user.name} ( {user.email} / {user.phone} )</p>
            <button style={{ marginLeft: "auto" }} onClick={() => deleteUser()} className="b-delete">видалити</button>
        </div>
    )
}