import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import "./index.scss";
import Poll from '../../poll/poll';
import firebase, { db } from "../../../js/firebase";

export default function Index(props) {
    const [polls, setpolls] = useState([]);
    const [currentpoll, setcurrentpoll] = useState();
    const [mode, setmode] = useState("loading");
    const topImgs = useRef();

    useEffect(() => {
        db.collection("polls").orderBy("created", "desc").get().then(data => {
            let polls = [];
            data.docs.map(doc => {
                let poll = { ...doc.data(), ...{ id: doc.id } };
                polls.push(poll);
            })
            setpolls(polls);
            setmode("ready");
        });
    }, []);

    useEffect(() => {
        if (!polls[0]) return;
        setcurrentpoll(polls[0]);
        // console.log(polls[0]);
        props.setHeaderPoll(polls[0]);
    }, [polls]);

    return (
        <div id="index-page">
            {
                mode == "loading" &&
                <div style={{ marginTop: "10px" }} className="loading-circle" />
            }
            <div id="poll-holder">
                {
                    currentpoll &&
                    <Poll
                        id={currentpoll.id}
                        header={currentpoll.header}
                        description={currentpoll.description}
                        elements={currentpoll.elements}
                    />
                }
            </div>
            {
                polls.length < 1 && mode != "loading" &&
                <p id="no-polls">На даний момент немає опитувань в яких ви би могли приймати участь</p>
            }
            <a target="_blank" href="https://lviv-croissants.com/"><h1>lviv-croissants.com</h1></a>
        </div>
    )
}